.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  color: #fff;
  z-index: 1000;
  transition: transform 0.5s;

  .left_container {
    display: flex;
    align-items: center;
    gap: 26px;

    button {
      background-color: transparent;
      border: none;
      height: 34px;
      cursor: pointer;
      margin-top: 2px;
      margin-left: 15px;
      transition: all 0.2s ease-in-out;
      border: 1px solid var(--accent-color);
      border-radius: 8px;
      padding: 6px;
      background: hsla(245, 97%, 62%, 15%);
      &:hover {
        transform: scale(1.1);
        background: hsla(245, 97%, 62%, 25%);
        border: 1px solid var(--accent-color);
      }
    }
  }

  .logo_container {
    display: flex;
    align-items: center;

    .logo {
      max-height: 40px;
      margin-right: 12px;
    }

    h3 {
      font-size: 22px;
      font-weight: 800;
      letter-spacing: 0.25px;
    }
  }

  .button_container {
    display: flex;
    align-items: center;
    gap: 8px;

    .mockDataLink {
      height: 34px;
      margin: 0 1.5rem;
      width: fit-content;
      min-width: 125px;
      display: flex;
      align-items: center;
      background: var(--accent-color);
      border-radius: 8px;
      border: none;
      font-size: 13px;
      font-weight: 700;
      padding: 0.65rem 1.25rem;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      gap: 4px;
      &:hover {
        background: var(--accent-color-darkened);
        transform: scale(1.05);
      }
    }

    .terra_link {
      height: 30px;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0.5rem 1rem;
      font-size: 12px;
      font-weight: 600;
      text-decoration: none;
      border: 1px solid #c1c1c1;
      border-radius: 25px;
      background: transparent;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      img {
        height: 14px;
      }

      &:hover {
        background: hsla(245, 97%, 62%, 25%);
        border: 1px solid var(--accent-color);
        transform: scale(1.05);

        .arrow{
          animation: .75s infinite a;
        }
      }
    }

    @keyframes a {
      50% {
        transform: translateX(2px)
      }
    }
  }
}
