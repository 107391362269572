.fieldSection {
  border-radius: 4px;
  width: 400px;

  @media screen and (min-width: 1750px) {
    width: 475px;
  }
}

.accordion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: normal;
  background-color: var(--card-bg);
  border-radius: 4px;
  width: 100%;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--card-header);
    padding: 16px 20px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s, border-radius 0.3s 0.3s;

    .title {
      font-size: 16px;
      font-weight: 600;
    }

    .icon {
      width: 20px;
      transform: rotate(180deg);
      transition: transform 0.3s;
    }

    &:hover {
      background-color: var(--card-header-darkened);
    }
  }

  .content {
    padding: 0 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid var(--border-color);
    border-top: 0;
    border-radius: 0 0 4px 4px;

    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in, max-height 0.4s ease-in,
      padding-top 0.3s ease-in 0.05s, padding-bottom 0.4s ease 0.2s;

    .fieldRow {
      display: flex;
      justify-content: space-between;
      border-radius: 6px;
      gap: 20px;
    }

    .labelContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .fieldLabel {
        font-size: 14px;
        font-weight: 500;
        color: var(--font-color-muted);
      }

      .secondaryLabel {
        font-size: 10px;
        font-weight: 500;
        color: var(--font-color-secondary);

        a {
          color: #ababb6;
          font-weight: 600;
          transition: all 0.3s;

          &:hover {
            text-decoration: underline;
            color: var(--font-color);
          }
        }
      }
    }

    .fieldValue {
      width: 175px;
      height: fit-content;
      display: flex;

      input {
        padding: 8px 12px;
        background-color: var(--input-bg);
        border: 1px solid var(--border-color);
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
      }

      .textValue {
        font-size: 18px;
        font-weight: 600;
        width: inherit;

        span {
          color: rgb(210, 40, 102);
          opacity: 0.65;
        }
      }
    }
  }

  &.opened {
    .top {
      border-radius: 4px 4px 0 0;
    }

    .content {
      opacity: 1;
      padding-top: 12px;
      padding-bottom: 12px;
      max-height: 450px;
    }

    .icon {
      transform: rotate(0deg);
    }
  }
}