.columnContainer {
  display: flex;
  min-height: 100vh;
  padding-bottom: 50px;
  position: relative;
  margin-top: 2rem;

  /* Firefox */
  & {
    scrollbar-width: auto;
    scrollbar-color: #dad5db var(--bg);
  }

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 16px;
    height: 18px;
  }

  &::-webkit-scrollbar-track {
    background: var(--bg);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b0acb1;
    border-radius: 10px;
    border: 5px solid var(--bg);
  }
}

.assetColumn {
  margin: 1rem 0 0 1rem;
  max-width: 400px;

  &_native {
    margin: 1rem 2.5rem 0 2rem;
    max-width: 400px;
  }

  @media screen and (min-width: 1750px) {
    max-width: 475px;

    &_native {
      max-width: 500px;
    }
  }
}

.lastColumn {
  display: flex;
  margin: 0 2.5rem;
  flex-shrink: 0;
  flex-basis: 10px;
  align-items: center;
  justify-content: center;
}

.addColumn {
  position: fixed;
  bottom: 25px;
  right: 25px;
  margin: 0 1rem;
  flex-shrink: 0;
  flex-basis: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    background-color: var(--card-bg);
    border: 1px solid var(--border-color);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 0.2s ease-in-out;

    img {
      width: 75%;
      height: 75%;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}
