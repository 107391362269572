@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  // --accent-color: #228BBC;
  // --accent-color: #4672ED;
  --accent-color: hsl(245, 97%, 62%);

  --accent-color-darkened: hsl(245, 90%, 55%);

  --bg: #0D0D11;
  --font-color: #FFFFFF;
  --font-color-muted: #E8E8E8;
  --font-color-secondary: #8A8AA0;

  --border-color: #393939;

  --card-bg: #17191D;
  --card-header: #272A2F;
  --card-header-darkened: hsl(218, 9%, 14%);

  --input-bg: #2A2B31;
  --input-bg-darkened: hsl(231, 8%, 15%);

  // old colors
  // --card-header: #21212C;
  // --input-bg: #1F1F2C;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  line-height: 120%;
  color: var(--font-color);
  font-family: "Gotham";
}

html,
body {
  max-width: 100vw;
  font-size: 14px;
  font-family: "Gotham", sans-serif;
  height: 100%;
  scroll-behavior: smooth;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  color: var(--font-color);
  background: var(--bg);

  &::-webkit-scrollbar {
    width: 20px;
    height: 20px;
    display: block
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: var(--border-color);
    border: 5px solid var(--bg)
  }

  &::-webkit-scrollbar-track {
    background-color: transparent
  }
}

a {
  color: inherit;
  text-decoration: none;
}

@import "_fonts.scss";