.url {
  display: flex;
  height: fit-content;

  .input_wrapper {
    display: flex;
    width: 100%;

    .input {
      width: 100%;
      padding: 16px;
      background-color: var(--input-bg);
      border: 1px solid var(--border-color);
      border-radius: 4px;
      font-weight: 600;
      line-height: 100%;
      text-align: right;
      font-size: 14px;
      border-right: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      &:focus {
        outline: none;
      }
    }

    .inputSuffix {
      padding: 4px 12px;
      background-color: var(--input-bg-darkened);
      border: 1px solid var(--border-color);
      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      font-weight: 600;
      display: flex;
      height: 100%;
      align-items: center;

      &:focus {
        outline: -webkit-focus-ring-color auto 5px;
      }

      svg {
        &:focus {
          outline: none;
        }
      }
    }
  }
}