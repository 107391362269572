.inputGroup {
  width: 175px;
  display: flex;
  align-items: center;

  .input {
    width: 100%;
    padding: 8px 12px;
    background-color: var(--input-bg);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-weight: 600;
    line-height: 100%;
    text-align: right;

    &.withSuffix {
      border-right: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &.withPrefix {
      border-left: 0 !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    &.error {
      border-width: 1px 1px 1px 10px !important;
      border-style: solid !important;
      border-color: rgb(191, 22, 80) rgb(191, 22, 80) rgb(191, 22, 80) rgb(236, 89, 144) !important;
    }
  }

  .inputSuffix {
    padding: 0px 6px;
    background-color: var(--input-bg-darkened);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: 600;
    display: flex;
    height: 100%;
    align-items: center;
  }

  .inputPrefix {
    padding: 0px 6px;
    background-color: var(--input-bg-darkened);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-weight: 500;
    display: flex;
    height: 100%;
    align-items: center;
  }
}
