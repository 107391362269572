.columnActions {
  display: flex;
  gap: 8px;

  button {
    background-color: var(--accent-color);

    padding: 4px 6px;
    border-radius: 10px;
    border: none;
    font-weight: 600;
    font-size: 10px;
    line-height: 1;
    display: flex;
    align-items: center;
    gap: 4px;

    -webkit-transition: background-color 0.3s, color 0.3s;
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;

    &.all {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover {
      background-color: var(--accent-color-darkened);
    }
  }
}